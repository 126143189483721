.header-icon {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 14px;
  flex-shrink: 0;
  cursor: pointer;
  transition: 0.3s;
}

.header-icon:hover {
  opacity: 0.7;
}

.header-icon:active {
  opacity: 0.7;
}

.header-rectifier {
  margin-top: 50px;
}

.header-rectifier-nobg {
  margin-top: 20px;
}

.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  height: 60px;
  background-color: #fff;
  box-shadow: 0px -2px 14px 0px rgba(69, 27, 27, 0.15);
  z-index: 10;
  border-radius: 0px 0px 5px 5px;
}

.header > h1 {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 10px;
  margin-top: 0;
}

.progress {
  position: relative;
  border-radius: 3px;
  background: #eee;
  width: 100%;
  height: 6px;
}

.progress > div {
  position: absolute;
  left: 0%;
  height: 6px;
  background: var(--primary-color);
  border-radius: 3px;
}
