.menu {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 5px 5px 0px 0px;
  background: #fff;
  box-shadow: 0px -2px 14px 0px rgba(69, 27, 27, 0.15);
  height: 58px;
  flex-shrink: 0;
  gap: 15px;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
}

.menu > button {
  width: 100%;
  display: flex;
  padding: 10px 20px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1.5px solid var(--primary-color);
  background-color: #fff;
  cursor: pointer;
  transition: 0.3;
}

.menu > button:last-child {
  background-color: var(--primary-color);
}
.menu > button:last-child > span {
  color: #fff;
}

.menu > button > span {
  color: var(--primary-color);
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%; /* 17.5px */
}

.menu > .menu-button-disabled {
  background-color: #eee !important;
  border-color: #eee !important;
  cursor: not-allowed !important;
}
.menu > .menu-button-disabled > span {
  color: var(--secondary-color) !important;
}

.menu > button:hover {
  opacity: 0.7;
}

.menu > button:active {
  opacity: 0.7;
}
