@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

* {
  box-sizing: border-box;
  --primary-color: #0095ae;
  --primary-bg-color: #0095ae;
  --secondary-color: #2e3f51;
  --secondary-bg-color: #2e3f51;
  --text-color: #2e3f51;
  --red: #d73838;
  --green: #2fd987;
}

html {
  font-family: "Poppins", sans-serif;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 10px;
  gap: 16px;
  /* background: url("./images/logo-white-45.png"); */
  /* background-size: 20%; */
}

.overflow-hidden {
  overflow: hidden;
}

.backdrop-filter-blur {
  backdrop-filter: blur(2px);
}

.list {
  /*...*/
  width: 100%;
}

h1 {
  color: var(--text-color);
}

p {
  color: var(--text-color);
  font-size: 14px;
  margin: 0;
}

span {
  color: var(--text-color);
}

button:focus,
input:focus,
textarea:focus {
  outline: none;
}

.required {
  color: #d73838;
}

/* start - Popup */

.popup {
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 10;
  background-color: white;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  justify-content: space-around;
}

.popup-close {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.popup > img {
  margin: 0 auto;
}

.popup-data {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.popup button {
  margin: 0 auto;
  display: inline-flex;
  padding: 10px 27px;
  gap: 10px;
  border-radius: 5px;
  background: var(--primary-color);
  color: #fff;
  font-size: 14px;
  border: none;
  align-items: center;
  cursor: pointer;
}

.popup button:hover {
  opacity: 0.7;
}

.popup button:active {
  opacity: 0.5;
}

.popup button.outline {
  border: solid 1px var(--primary-color);
  color: var(--primary-color);
  background: #fff;
}

.variable-container {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  border-radius: 5px;
  background: #fff;
  box-shadow: 2px 4px 12px 0px rgba(80, 41, 41, 0.15);
  padding: 12px;
  gap: 10px;
}

/* end - Popup */

/* start - ButtonPicture */

.dropdown {
  display: flex;
  flex-direction: column;
  margin: auto;
  position: relative;
}

.dropdown-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50px;
  gap: 10px;
  justify-content: flex-start;
}

.button-picture-container {
  position: relative;
  flex-direction: column;
  border-radius: 5px;
}

.button-picture-viewer {
  display: flex;
  padding: 10px;
}

.button-picture-viewer > img {
  margin: auto;
  max-width: 100%;
  max-height: 100px;
  border-radius: 5px;
}

.button-picture-icon {
  position: absolute;
  height: 30px;
  width: 30px;
  flex-shrink: 0;
  top: 10px;
  left: 10px;
  cursor: pointer;
  background-color: #fff;
  padding: 4px;
  border-bottom-right-radius: 5px;
}

.button-picture-icon-right {
  position: absolute;
  height: 30px;
  width: 30px;
  flex-shrink: 0;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background-color: #fff;
  padding: 4px;
  border-bottom-right-radius: 5px;
}

.button-picture {
  display: flex;
  padding: 10px 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  background: var(--primary-color);
  color: #fff;
  font-size: 14px;
  border: none;
  transition: 0.3s;
  cursor: pointer;
  box-shadow: 2px 4px 12px 0px rgba(80, 41, 41, 0.15);
}

.button-picture-disabled {
  background-color: #eee !important;
  border-color: #eee !important;
  cursor: not-allowed !important;
}
.button-picture-disabled > span {
  color: var(--secondary-color) !important;
}

.button-picture > span {
  color: #fff;
}

.button-picture:hover {
  opacity: 0.7;
}

.button-picture:active {
  opacity: 0.5;
}

/* end - ButtonPicture */

/* start - Textarea */

textarea {
  width: 100%;
  flex-shrink: 0;
  border-radius: 5px;
  background: #fff;
  box-shadow: 2px 4px 12px 0px rgba(80, 41, 41, 0.15);
  border: 0;
  padding: 10px;
  font-size: 14px;
  color: var(--text-color);
}

/* end - Textarea */

/* start - ButtonDocument */

.button-document {
  display: flex;
  padding: 12px 16px;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  border: none;
  background-color: #fff;
  border: 1.5px dashed var(--primary-color);
  transition: 0.3s;
  cursor: pointer;
}

.button-document > span {
  color: var(--primary-color);
}

.button-document:hover {
  opacity: 0.7;
}

.button-document:active {
  opacity: 0.5;
}

/* end - ButtonDocument */

/* start - YesNo */

.yes-no {
  display: flex;
  width: 100%;
  flex-shrink: 0;
  border-radius: 5px;
  gap: 10px;
  padding: 6px;
  border: 1.5px solid var(--primary-color);
  justify-content: space-between;
  align-items: center;
}

.yes-no > span {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-color);
  border-radius: 2.5px;
  padding: 6px;
  transition: 0.3s;
  font-size: 14px;
  cursor: pointer;
}

.yes-no > span:hover {
  opacity: 0.7;
}

.yes-no > span:active {
  opacity: 0.5;
}

.yes-no > span.active {
  color: #fff;
  background-color: var(--primary-color);
}

/* end - YesNo */

/* start - Checkbox */

.checkbox {
  display: flex;
  gap: 10px;
  font-size: 14px;
  align-items: center;
}

.checkbox > input {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  accent-color: var(--primary-color);
  border-radius: 6px;
  border: 2px solid var(--primary-color);
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  background-color: #fff;
}

.checkbox > span {
  cursor: pointer;
}

.checkbox > span:hover {
  opacity: 0.7;
}

.checkbox > span:active {
  opacity: 0.5;
}

.checkbox > input:checked {
  background-color: var(--primary-color);
}

/* end - Checkbox */

/* start - InputText */

.input-text {
  display: flex;
  flex-direction: column;
  gap: 5px;
  z-index: 1;
}

.input-text > span {
  font-size: 14px;
}

.input-text > input {
  width: 100%;
  flex-shrink: 0;
  border-radius: 5px;
  background: #fff;
  box-shadow: 2px 4px 12px 0px rgba(80, 41, 41, 0.15);
  border: 0;
  font-size: 14px;
  padding: 12px;
}

/* end - InputText */

/* start - ButtonSignature */

.button-signature {
  display: flex;
  padding: 10px 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  background: var(--primary-color);
  color: #fff;
  font-size: 14px;
  border: none;
  transition: 0.3s;
  cursor: pointer;
}

.button-signature > span {
  color: #fff;
}

.button-signature:hover {
  opacity: 0.7;
}

.button-signature:active {
  opacity: 0.5;
}

.button-signature-modal-container {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 10;
  justify-content: center;
  backdrop-filter: blur(3px);
}

.button-signature-modal-container-absolute {
  position: absolute;
}

.button-signature-modal {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 15px;
  max-width: 80%;
  margin: auto;
  border-radius: 5px;
  gap: 15px;
}

.button-signature-modal > div {
  display: flex;
  gap: 15px;
}

.button-signature-modal > div > button {
  width: 100%;
  justify-content: center;
}

.button-signature-modal > div > button.outline {
  background-color: #fff;
  color: var(--primary-color);
  border: 1.5px solid var(--primary-color);
}

.sigCanvas {
  border: 1.5px var(--primary-color) solid;
  border-radius: 5px;
}

/* end - ButtonSignature */

/* start - Loader */

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}
.loader::before,
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #fff;
  animation: prixClipFix 2s linear infinite;
}
.loader::after {
  border-color: var(--primary-color);
  animation: prixClipFix 2s linear infinite, rotate 0.5s linear infinite reverse;
  inset: 6px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

/* end - Loader */

.cursor-pointer {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  transition: 0.3s;
}

.cursor-pointer:hover {
  opacity: 0.7;
}

.cursor-pointer:active {
  opacity: 0.5;
}

.cursor-disabled {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: not-allowed;
  transition: 0.3s;
}

.cursor-disabled:hover {
  opacity: 0.7;
}

.cursor-disabled:active {
  opacity: 0.5;
}

.calendar-table {
  width: 100%;
  text-align: center;
}

.calendar-table th {
  margin: 10px;
}

.calendar-table td > div {
  display: inline-flex;
  color: #fff;
  border-radius: 20px;
  height: 28px;
  width: 28px;
  justify-content: center;
  align-items: center;
  background-color: var(--green);
}

.calendar-table td > div > span {
  display: inline-block;
  color: #fff;
}

.calendar-table td > div.active {
  background-color: #fff;
}

.calendar-table td > div.active > span {
  color: var(--primary-color);
}

.calendar-table td > div.disabled {
  background-color: #9d9d9d;
  cursor: not-allowed !important;
}

.calendar-table td > div.disabled > span {
  color: #a9a9a9;
}
.calendar-table td > div.disabled:hover,
.calendar-table td > div.disabled:active {
  opacity: 1 !important;
}

.select-time {
  display: inline-block;
  padding: 4px;
  border-radius: 4px;
  margin-right: auto;
}

.select-time.active {
  display: inline-block;
  padding: 4px;
  border-radius: 4px;
  background-color: var(--primary-color);
  color: #fff;
  margin-right: auto;
}

/* START - GARAGE */

.table-garage {
  width: 100%;
  table-layout: fixed;
}

.table-garage th {
  text-align: left;
}

.table-garage th,
.table-garage td {
  border-right: rgba(11, 40, 70, 0.4) 1px solid;
}

.table-garage tr th:last-child,
.table-garage tr td:last-child {
  border-right: none;
}

.table-garage tr > th:first-child {
  width: 24px;
}

.table-garage th span {
  color: rgba(11, 40, 70, 0.4);
  font-weight: normal;
  font-size: 14px;
}

.table-garage th span:nth-child(2) {
  color: var(--primary-color);
}

.table-garage td {
  text-align: left;
}

.table-garage td > div > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 5px;
  height: 25px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 2px 4px 12px 0px rgba(80, 41, 41, 0.15);
  text-align: center;
  font-size: 14px;
}

.table-garage td > div > div > span {
  color: rgba(11, 40, 70, 0.4);
}

.table-tender {
  width: 100%;
  margin: 0;
  font-size: 14px;
  border-spacing: 0;
}

.table-tender > thead {
  color: var(--text-grey);
}

.table-tender {
  width: 100%;
  margin: 0;
  font-size: 14px;
  border-spacing: 0;
}

.table-tender {
  width: 100%;
  margin: 0;
  font-size: 14px;
  border-spacing: 0;
}

.table-tender > tbody > tr:nth-child(odd) {
  background-color: #f8f8f8;
}

.table-tender > tbody td,
.table-tender > tbody th {
  border-top: 0.7px solid var(--light-grey);
}

.table-tender td,
.table-tender th {
  padding: 12px;
  text-wrap: nowrap;
  font-size: 14px;
}

.bg-red {
  background-color: #d73838;
}

.bg-green {
  background-color: #2fd987;
}

.bg-orange {
  background-color: #f2711c;
}

.white {
  color: #fff;
}

.table-button {
  width: 100%;
  border-radius: 8px;
  border: none;
  background-color: var(--primary-color);
  color: #fff;
  height: 25px;
}

.align-center {
  align-items: center;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}
